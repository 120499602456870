(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:License
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('License', License);

  function License($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/persons/:personId/licences', {personId: '@_personId'});
  }
}());
